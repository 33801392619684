import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function insertMark(mark){
    if(mark != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 12,
                    value: mark
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Добавлена новая марка авто: ' + mark)
                    this.marks.push({
                        value: response.data,
                        text: mark
                    })
                    this.newMark = ''
                    UIkit.modal("#modal-insert").hide()
                    UIkit.notification({message: localizeFilter('Insert'), pos:'bottom-center'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}