import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function changeMarks(mark, event){
    if(mark != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 11,
                    id: mark.value,
                    mark: event.target.value
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Изменение элемента в списке марок: ' + mark.text + ' -> ' + event.target.value )
                    mark.text = event.target.value
                    UIkit.notification({message: localizeFilter('Changed'), pos:'bottom-center'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}